<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton :color="filters ? 'dark' : 'warning'" size="sm" @click="toggleFilters" style="height: 26px; padding: 2px 5px;"><CIcon name="cil-filter"/></CButton>
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Concurso</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow v-if="filters">
        <CCol md="3"></CCol>
        <CCol md="3"></CCol>
        <CCol md="3"></CCol>
        <CCol md="3">
          <div role="group" class="form-group" style="margin: -10px 0px 10px">
            <label> Proveedor (Marca):</label>
            <CSelect
              :value.sync="brandSelected" 
              :options="brandsOptions"
              @change="changeFilters"
            />                        
          </div>
        </CCol>
      </CRow>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #brand_name="{item}"><td class="text-center w200">{{item.brand_name ? item.brand_name : 'NO DEFINIDO'}}</td></template>
        <template #type="{item}"><td class="w200 text-center">
          <span>{{item.type == 'video' ? 'Capacitación' : (item.type == 'sales' ? 'Venta Mínima' : (item.type == 'customers' ? 'Clientes Mínimos' : item.type == 'boxes' ? 'Cajas Mínimo' : '-'))}}</span>
        </td></template>
        <template #name="{item}"><td>
          <span style="font-weight: bold;">{{item.name}}</span><br />
          <span>{{item.description}}</span>
        </td></template>
        <template #deadline="{item}"><td class="w300 text-center"><span>{{item.start_date | date}} - {{item.end_date | date}}</span></td></template>
        <template #is_active="{item}"><td class="w100 text-center">
          <span>
            <CButton v-if="item.is_active" color="success" size="sm" @click="deactiveProcess(item.id)"><CIcon name="cil-check"/></CButton>
            <CButton v-if="!item.is_active" color="danger" size="sm" @click="reactiveProcess(item.id)"><CIcon name="cil-ban"/></CButton>
          </span>
        </td></template>
        <template #actions="{item}">
          <td class="table_actions b6">
            <CButton color="info" size="sm" @click="showChallenge(item)"><CIcon name="cil-zoom"/></CButton>
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <ChallengeModal ref="challengeModal" @store="storeProcess" @update="updateProcess"></ChallengeModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import ChallengeModal from './ChallengeModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/challenges';
import brands_ws from '../../services/brands';
import store from '../../store'
import router from '../../router/index'

export default {
  name: 'ChallengesTable',
  components: { ChallengeModal, DeleteModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'brand_name', label: 'Proveedor (Marca)'},
          {key: 'type', label: 'Tipo'},
          {key: 'name', label: 'Nombre'},
          {key: 'deadline', label: 'Vigencia'},
          {key: 'is_active', label: 'Status'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      filters: false,
      toasts: [],
      brands: [],
      brandsOptions: [],
      brandSelected: "",
      challenge_id: 0,
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  mounted: async function() {
    let response = await brands_ws.get(); 

    if(response.type == "success"){
      this.brands = response.data;

      this.brandsOptions = await this.$parseSelectOptionsOrdered(this.brands, "id", "name", [{value: '0', label: 'Todos los Proveedores'}]);
    }
  },
  methods: {
    toggleFilters () {
      this.filters = !this.filters;
    },
    async changeFilters () {
      this.loading();

      let filters = this.getFilters();
      let response = await ws.getFiltered(filters);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.loaded();
    },
    getFilters(){
      let filters = {
        brand : this.brandSelected
      };

      return filters;
    },
    showChallenge (item) {
      router.push({path: '/challenges/'+item.id+'/view'});
    },
    storeModal () {
      this.$refs.challengeModal.storeModal();
    },
    updateModal (item) {
      this.$refs.challengeModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Promoción", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        let data = response.data;
        this.brandSelected = data[0].brand_id;
        this.$emit("refresh", data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        let data = response.data;
        this.brandSelected = data[0].brand_id;
        this.$emit("refresh", data );

        console.log(data);
        console.log(this.brandSelected)
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deactiveProcess(id) {
      let response = await ws.deactive({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async reactiveProcess(id) {
      let response = await ws.reactive({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
